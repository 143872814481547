@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;700&display=swap');

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

.maplibregl-ctrl-attrib {
  font-family: "Nunito", "Roboto", sans-serif;
  font-size: 0.8em;
}

.map-tooltip .maplibregl-popup-content {
  font-family: "Nunito", "Roboto", sans-serif;
  padding: 4px 8px;
}

.map-tooltip .maplibregl-popup-content h3 {
  margin: 0;
}

.map-counter-tooltip .maplibregl-popup-content {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  font-size: 0.9em;
  padding: 0;
}

.map-custom-popup {
  z-index: 1000;
}

.map-custom-popup .maplibregl-popup-content {
  padding: 0;
}

.flowmap-tooltip {
  z-index: 10000;
}

.cluster-marker {
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  width: 30px;
  z-index: 1;
}

.deck-tooltip {
  background-color: #fff !important;
  border-radius: 8px;
  color: inherit !important;
  font-family: "Nunito", "Roboto", sans-serif;
  padding: 8px !important;
  z-index: 100 !important;
}

@media print {
  #root {
    display: block;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
